<template>
  <form @submit.prevent="createSeries">
    <div class="row">
      <div class="col my-auto">
        <label for="">Series title <small>(internal use only)</small></label>
        <input
          type="text"
          v-model="form.title"
          class="form-control"
          placeholder="A snazzy email series title..."
        />
      </div>
      <div class="col-auto mt-auto">
        <button class="btn btn-primary" :disabled="form.title.length === 0">
          Create
        </button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      form: {
        title: "",
      },
    };
  },
  methods: {
    createSeries() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/email-series/api", this.form)
        .then(({ data }) => {
          this.$EventBus.$emit("email-series-refresh");
          this.form.title = "";
          this.editingSeries = data.series;
          this.$EventBus.$emit("alert", data);
        });
    },
  },
};
</script>

<style>
</style>