<template>
  <div
    class="modal fade"
    id="email-series-edit-modal"
    tabindex="-1"
    aria-labelledby="emailSeriesEditModal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="emailSeriesEditModal">
            Editing series - <strong>{{ series.title }}</strong>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal" data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p class="alert alert-primary" v-if="chosenTemplates.length === 0">
            Select a template using the dropdown, once you have added all
            desired templates, specify the delay for each email. <br />
            If the series is started now, then each email delay is the number of
            days from now to send that email
            <br />
            You may only use a template once in each series.
          </p>

          <div class="row">
            <div class="col my-auto">
              <label for="">Choose a template and add it to this series</label>
              <select class="form-control" v-model="selectedTemplate">
                <option selected disabled>Select an Email Template</option>
                <option v-for="t in templates" :key="t.id" :value="t">
                  {{ t.title }}
                </option>
              </select>
            </div>
            <div class="col-auto mt-auto">
              <button
                class="btn btn-primary"
                :disabled="!selectedTemplate"
                @click="addTemplate"
              >
                Add
              </button>
            </div>
          </div>

          <!--  -->

          <ul class="list-group-flush">
            <li
              class="list-group-item"
              v-for="t in chosenTemplates"
              :key="t.id"
            >
              <div class="row">
                <div class="col my-auto">
                  <h5 class="fw-bold mb-0">{{ t.template.title }}</h5>
                </div>
                <div class="col-auto my-auto">
                  <label for="">Delay in days</label>
                  <input
                    type="number"
                    v-model="t.delay"
                    class="form-control"
                    @input="sortTemplates"
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal" data-dismiss="modal"
            aria-label="Close"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary ms-auto"
            @click="updateSeries"
          >
            Save email series
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["series"],
  data() {
    return {
      selectedTemplate: null,
      templates: [],
      chosenTemplates: [],
    };
  },
  methods: {
    sortTemplates() {
      setTimeout(() => {
        this.chosenTemplates.sort((n1, n2) => n1.delay - n2.delay);
      }, 1500);
    },
    addTemplate() {
      if (this.chosenTemplates.length == 0) {
        var delay = 0;
      } else if (this.chosenTemplates.length == 1) {
        var delay = 1;
      } else if (this.chosenTemplates.length == 2) {
        var delay = 3;
      } else if (this.chosenTemplates.length == 3) {
        var delay = 5;
      }

      var data = {
        template: this.selectedTemplate,
        delay: delay,
      };

      this.chosenTemplates.push(data);

      var index = this.templates.findIndex(
        (x) => x.id === this.selectedTemplate.id
      );

      this.templates.splice(index, 1);
      this.selectedTemplate = null;
    },
    fetchTemplates() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/email-series/api/templates")
        .then(({ data }) => {
          this.templates = data;
        });
    },
    updateSeries() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL + "/email-series/api/" + this.series.id,
          {
            templates: this.chosenTemplates,
          }
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", "Email series updated");
          $("#email-series-edit-modal").modal("hide");
        });
    },
  },
  mounted() {
    this.series.items.forEach((i) => {
      i.title = i.template.title;
      var data = {
        template: i,
        delay: i.delay,
      };

      this.chosenTemplates.push(data);
    });
    this.fetchTemplates();
    $("#email-series-edit-modal").modal("show");

    $("#email-series-edit-modal").on("hide.bs.modal", (e) => {
      this.$emit("close");
    });
  },
};
</script>

<style>
</style>