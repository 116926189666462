<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3 spark-settings-tabs">
        <marketing-nav></marketing-nav>
      </div>

      <!-- Tab cards -->
      <div class="col-md-9 ps-0">
        <div class="row">
          <div class="col">
            <div>
              <div class="card border-0 shadow-sm">
                <div class="card-header border-0">
                  <h5 class="mb-0">Email Series</h5>
                </div>
                <div class="card-body">
                  <p class="alert alert-primary">
                    Using templates created above you may setup series of
                    emails, this is 2 or more email templates with a delay
                    between each email sent.
                    <br />
                    To create a series, specify a title, then add emails in the
                    order you wish to send them specifying a delay for each
                  </p>

                  <add-series class="mb-5"></add-series>

                  <!--  -->

                  <series-list @editSeries="editSeries"></series-list>

                  <!--  -->
                </div>
              </div>

              <edit-series-modal
                v-if="selectedSeries"
                :series="selectedSeries"
                @close="closeEdit"
              ></edit-series-modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MarketingNav from "../../partials/MarketingNav";
import AddSeries from "./series-partials/AddSeries";
import SeriesList from "./series-partials/SeriesList";
import EditSeriesModal from "./series-partials/EditModal";

export default {
  props: [],
  data() {
    return {
      editingSeries: null,
      selectedSeries: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    path() {
      return this.$router.currentRoute.fullPath;
    },
  },
  methods: {
    closeEdit() {
      this.selectedSeries = null;
      this.$EventBus.$emit("email-series-refresh");
    },
    editSeries(series) {
      this.selectedSeries = series;
    },

    addTemplate(template) {
      if (this.form.templates.length == 0) {
        var delay = moment();
      } else if (this.form.templates.length == 1) {
        var delay = moment().add(1, "days");
      } else if (this.form.templates.length == 2) {
        var delay = moment().add(3, "days");
      } else if (this.form.templates.length == 3) {
        var delay = moment().add(5, "days");
      }

      var data = {
        template: template,
        delay: delay,
      };

      this.form.templates.push(data);
    },
  },
  mounted() {},
  components: {
    MarketingNav,
    AddSeries,
    SeriesList,
    EditSeriesModal,
  },
};
</script>