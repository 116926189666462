<template>
  <div class="">
    <div class="row">
      <div class="col">
        <div v-if="emailSeries.length > 0">
          <table class="table table-hover">
            <thead class="thead-dark">
              <th scope="col">ID</th>
              <th scope="col">Name</th>
              <th scope="col"></th>
            </thead>
            <tbody>
              <tr v-for="es in emailSeries" :key="es.id">
                <td>{{ es.id }}</td>
                <td>{{ es.title }}</td>
                <td class="text-end">
                  <button
                    v-if="es.user_id"
                    class="btn btn-light btn-sm ms-1"
                    @click.prevent="editSeries(es)"
                  >
                    <i class="fa fa-edit text-primary"></i>
                  </button>
                  <button
                    class="btn btn-light btn-sm ms-1"
                    @click.prevent="deleteSeries(es)"
                  >
                    <i class="fa fa-trash text-danger"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <p class="text-center">No email series have been setup yet!</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["fetchSeries"],
  data() {
    return {
      loading: true,
      emailSeries: [],
    };
  },
  methods: {
    loadSeries() {
      console.log("fetching");
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/email-series/api")
        .then(({ data }) => {
          this.emailSeries = data;
          this.loading = false;
          this.$emit("refreshed");
        });
    },
    deleteSeries(es) {
      if (
        confirm(
          "Are you sure you wish to delete this email series? This will not impact email series already issued to clients!"
        )
      ) {
        this.$axios
          .delete(process.env.VUE_APP_API_URL + "/email-series/api/" + es.id)
          .then(({ data }) => {
            this.loadSeries();
            this.$EventBus.$emit("alert", "Series deleted");
          });
      }
    },
    editSeries(series) {
      this.$emit("editSeries", series);
    },
  },
  mounted() {
    this.$EventBus.$on("email-series-refresh", () => {
      this.loadSeries();
    });
    this.loadSeries();
  },
};
</script>